'use client';

import { addPrefix } from '@/utils/clsxUtils';
import { Image, Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import Link from 'next/link';
import { useEffect } from 'react';

interface IProps {
  delay: number;
  language: string;
  IOSLink: string;
  Android: string;
  mobileLink: string;
  initShow?: boolean;
}


export default function AudioModal(props: IProps) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  useEffect(() => {
    let timer = setTimeout(() => {
      onOpen();
    }, props.delay * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [props.delay, onOpen]);


  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      hideCloseButton
      isDismissable={false}
      placement="top-center"
      radius='none'
      size='full'
      classNames={{
        base: `${addPrefix('max-md:', ['w-full'])} w-[1300px]`,
        header: `${addPrefix('max-md:', ['w-full'])} h-12 w-[1300px] px-[40px]`,
        body: 'w-[1300px] h-[750px] p-0',
      }}
    >

      <ModalContent className='bg-transparent pt-[145px] max-md:pt-14 overflow-auto pb-16 max-md:'>
        {
          (onClose) => (
            <>
              <ModalHeader className='py-0 flex-row-reverse max-md:px-4 max-md:pb-[10px]'>
                <Image alt='close' radius='none' src='/close.png' className='cursor-pointer w-10 h-10 max-md:w-7 max-md:h-7' onClick={onClose} />
              </ModalHeader>
              <ModalBody >
                {/* pc */}
                <div className='relative w-[1200px] h-[750] max-md:hidden'>
                  <Image loading='eager' src={`/pop-${props.language ?? 'en'}.png`} radius='none' alt='down-load-app' width={1200} height={750} />
                  <Link href={props.IOSLink} target='_blank' className='absolute right-[162px] bottom-[69px]'>
                    <Image src='/app-store.png' radius='none' alt='App Store' width={135} height={40} />
                  </Link>
                  <Link href={props.Android} target='_blank' className='absolute right-[162px] bottom-[19px]'>
                    <Image src='/google-play-badge.png' radius='none' alt='App Store' width={135} height={40} />
                  </Link>
                </div>
                {/* mobile */}
                <div className='hidden max-md:block relative w-full px-4'>
                  <Link href={ props.mobileLink } target='_blank'>
                    <Image loading='eager' src={ `/mobile-pop-${props.language ?? 'en'}.png` } radius='none' className='w-full object-contain' alt='download app' />
                  </Link>
                </div>
              </ModalBody>
            </>
          )
        }
      </ModalContent>
    </Modal>
  );
}
