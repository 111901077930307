export interface IPaginationProps {
  total: number;
  curPage: number;
  className?: string;
  range?: number;
  changePrefix?: string;
  goPrevPage?: () => void;
  goNextPage?: () => void;
  onChange?: (newPage: number) => void;
}

export enum DotTypeEnum  {
  PREV_DOT = 'prev dot',
  NEXT_DOT = 'next dot',
  DOT = 'dot',
}
