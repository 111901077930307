'use client';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { dealLng } from '@/utils';
import { Image } from '@nextui-org/react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
interface IProps {
  imgURL?: string;
  title: string;
  author: string;
  goPath?: string;
  onPlay?: () => void;
}

export default function VerticalCard(props: IProps) {
  const params = useParams();
  return (
    <div className='vertical w-full rounded-[20px] border-[1px] border-solid border-[rgba(255,255,255,0.14)] bg-[#141414]'>
      <div className='flex justify-center pt-8 bg-[#262626] rounded-[20px] pb-[45px] relative'>
        <Link href={`${dealLng(params?.lng as string || 'en')}${props.goPath}`}>
          <Image
            src={props.imgURL ?? '/test.png'}
            alt={props.title}
            radius='none'
            height={225}
            width={150}
            className='object-cover w-[150px] h-[225px] rounded-2xl'
          />
        </Link>
        <div className='play' style={{ position: 'absolute', bottom: '-25px' }}>
          <Link href={`${dealLng(params?.lng as string || 'en')}${props.goPath}`}>
          <Image loading='lazy' src={ListenBriefImg.PlayAudio} className='cursor-pointer' alt='play' />
          </Link>
        </div>
      </div>
      <div className='book-content pt-10 px-[10px] pb-14'>
        <p className='line-clamp-2 text-center font-semibold'>{props.title}</p>
        <p className='pt-[6px] text-center text-sm text-[rgba(255,255,255,0.45)]'>{props.author}</p>
      </div>
    </div>
  );
}
