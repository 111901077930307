'use client';

import { useClientTranslation } from '@/hook/translate';
import clsx from 'clsx';
// import { Image } from '@nextui-org/react';

import styles from '@/brief/css/module/home.module.scss';
import { ListenBriefImg } from '@/config/img/listenbiref';
import Image from 'next/image';



export default function Header() {
  const { t } = useClientTranslation();
  const imageLoader = ({ src }: { src: string } ) => {
    return src;
  };
  
  return (

    <>
      <section className={clsx('relative px-[150px] h-[800px] flex flex-col justify-center max-md:h-[300px] max-md:px-4 bg-listenbrief-top-image bg-no-repeat bg-top bg-contain  max-md:bg-cover')}>
        <Image loader={ imageLoader } priority fill src='https://cdn.buildlearn.com/image/top-image.png' alt='background' className='absolute top-0 left-0 -z-10'   />
        <div className='flex items-center justify-center max-md:hidden'>
          <Image loader={ imageLoader } src={ListenBriefImg.FooterLogo} alt='logo' width={141} height={81} />
        </div>
        <h1 className='font-black text-[100px] leading-[136px] text-center break-words max-md:text-4xl'>{t('title', 'listenBriefs')}</h1>
        <h3 className='mt-2 px-28 max-md:px-0 font-normal text-lg break-words text-center max-md:text-xs'>{t('detailTitle', 'listenBriefs')}</h3>
      </section>
      <section className='explore-text max-md:pb-[15px]'>
        <div className='flex items-center justify-center max-md:pb-[12px]'>
          <Image loader={ imageLoader } src={ListenBriefImg.DownArrow} width={48} height={48} alt='logo' className=' w-12 h-12 max-md:h-10 max-md:w-10' />
        </div>
        <h3 className={
          clsx('text-center font-semibold text-3xl pt-[5px] max-md:text-base')
        }>
          <span className={styles.textColor}>{t('exploreAudio', 'listenBriefs')}</span>
        </h3>
      </section>
    </>
  );
}
